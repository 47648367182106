import React, { useState } from "react"
import ReactMarkdown from "react-markdown"
import { Link } from "gatsby"
import { useWindowSize } from "../../hooks/window-size"

const Content = props => {
  const text = props.Content
  const [isReadMore, setIsReadMore] = useState(true)

  const [windowSize, setWindowSize]=useState(false)


  const [windowWidth] = useWindowSize()
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore)
  }

  const smallDevice = windowWidth > 1200
  const mediumSizeDevice = windowWidth < 1700

  const ipad=windowWidth>768 && windowWidth<991;
  const ipadLarge=windowWidth>768 && windowWidth<1199;
  const mobileDevice=windowWidth<768

  

  const sliceText =
    smallDevice && mediumSizeDevice ? text.slice(0, 120) : text.slice(0, 105)

  const textLength =smallDevice && mediumSizeDevice ? text.length > 122:text.length>107

  const searchDes=smallDevice && mediumSizeDevice ?text.slice(0,100):text.slice(0,130);
  const searchDes1 = smallDevice && mediumSizeDevice ? text.slice(0,300) : text.slice(0,130);

  const parsedText=searchDes?.replace(/\r\n/g, ' ')?.replace(/<br>/g, " ")
  const parsedTex2 = searchDes1?.replace(/\r\n/g, ' ')?.replace(/<br>/g, " ")
  const ipadText=ipad?text.slice(0,90):text.slice(0,100);
  const parsedText1=ipadText?.replace(/\r\n/g, ' ')?.replace(/<br>/g, " ")

  const newHomesText=mobileDevice?text.slice(0,160):ipadLarge?text.slice(0,120):text.slice(0,450);

  

  return (
    <p className={`inc-dec-content ${props?.className ?? ''}`}>
      {props.page==="search-result"&&ipad? 
      <div className="search-result-more-content">
      <ReactMarkdown
        source={
          parsedText1+"..."
        }
        allowDangerousHtml
        className="content"
      />
      <Link to={`/${props.uriStr}${props.hit.slug}-${props.hit.objectID?props.hit.objectID:props.hit.id}/`} className="search-result-more">
        Read More
      </Link>
      </div>
      
        :

      props.page==="search-result"?      
      
      <div className="search-result-more-content">
      <ReactMarkdown
        source={
          parsedText+"..."
        }
        allowDangerousHtml
        className="content"
      />
      <Link to={`/${props.uriStr}${props.hit.slug}-${props.hit.objectID?props.hit.objectID:props.hit.id}/`} className="search-result-more">
        Read More
      </Link>
      </div>
      :
      props.page === "search-result-custom-content" ?
        <div>
          <ReactMarkdown source={parsedTex2 + "..."} allowDangerousHtml className="content" />
        </div>
      :
      props.page==="new-search-result"?      
      
      <div className="search-result-more-content new-homes">
      <ReactMarkdown
        source={
          `${newHomesText}${newHomesText.length>449?"...":""}`
        }
        allowDangerousHtml
        className="content"
      />
      
      </div>
    :
    props.page==="auction-result"?
    <div className="search-result-more-content">
      <ReactMarkdown
        source={
          parsedText+"..."
        }
        allowDangerousHtml
        className="content"
      />
      <a href={props.linkPage} className="search-result-more">
        Read More
      </a>
      </div>
      :

    <div className="para-content">
      <ReactMarkdown
        source={
          isReadMore ? (textLength ? `${sliceText}...` : `${sliceText}`) : text
        }
        allowDangerousHtml
        className="content"
      />
      <span onClick={toggleReadMore} className="more-or-less">
        {/* {isReadMore ? "SHOW MORE" : "SHOW LESS"} */}
        {textLength ? (isReadMore ? "Show More" : "Show Less") : ""}
      </span>
      </div>
}

      
    </p>
  )
}

export default Content
